import React, { useContext, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { UserContext } from "../Pages/Home";
import { Icon } from "@iconify/react";
import clients from "./ClientsImports";
import services from "./ServicesImports";

const Input = ({
  ...props
}: React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>) => (
  <input
    {...props}
    className="w-full lg:w-80 h-12 border border-orange-400 block rounded px-2 "
  />
);

function ContactUs() {
  const { language } = useContext(UserContext);

  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);

  const handleRecaptchaChange = (value: string | null) => {
    setRecaptchaValue(value); // Actualiza el estado con el valor del reCAPTCHA
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!recaptchaValue) {
      alert("Por favor, complete el reCAPTCHA");
      return;
    }
    // alert("Formulario enviado exitosamente");
  };

  return (
    <div
      id="contact"
      className="lg:shadow-2xl rounded w-full block md:pb-20 lg:flex justify-evenly shadow-2xl"
    >
      <div className="max-w-7xl flex justify-center flex-col items-center space-y-20">
        <div
          // style={{
          //   borderColor: "rgb(0,37,83)",
          //   borderBottomRightRadius: 60,
          //   borderTopLeftRadius: 60,
          // }}
          className="py-10 w-full lg:w-auto px-10 lg:px-10
          first-letter: bg-customOrange flex flex-col items-center"
        >
          <div className="mb-4 w-full text-center">
            <p className="text-3xl lg:text-4xl family-jost text-customBlue">
              {language === 0
                ? "¿Cómo podemos ayudarte?"
                : "How we can help you?"}
            </p>
          </div>
          {/* FORM  */}
          <form className="flex flex-col lg:flex-row" onSubmit={handleSubmit}>
            <div className="space-y-4 flex flex-col justify-center">
              <Input
                type={"text"}
                placeholder={
                  language === 0 ? "Nombre de la Compañía" : "Company name"
                }
              />
              <select className="w-full lg:w-80 h-12 border border-orange-400 block rounded px-2">
                <option value="" disabled selected>
                  {language === 0 ? "Elige un sector" : "Choose an sector"}
                </option>
                {clients.map((client) => (
                  <option
                    key={client.name}
                    value={language === 0 ? client.name : client.englishName}
                  >
                    {language === 0 ? client.name : client.englishName}
                  </option>
                ))}
              </select>
              <Input
                type={"text"}
                placeholder={
                  language === 0 ? "Nombres y apellidos" : "Name and last name"
                }
              />
              <Input
                type={"text"}
                placeholder={
                  language === 0 ? "Correo electrónico" : "Email address"
                }
              />
              <Input
                type={"text"}
                placeholder={language === 0 ? "Teléfono" : "Phone number"}
              />
              <select className="w-full lg:w-80 h-12 border border-orange-400 block rounded px-2">
                <option value="" disabled selected className="">
                  {language === 0 ? "Elige un servicio" : "Choose an service"}
                </option>
                {services.map((service) => (
                  <option
                    key={service.name}
                    value={language === 0 ? service.name : service.englishName}
                  >
                    {language === 0 ? service.name : service.englishName}
                  </option>
                ))}
              </select>
              <textarea
                placeholder={
                  language === 0
                    ? "Escribe tu consulta aquí"
                    : "Write your query here"
                }
                className="mt-5 lg:pt-2 border-2 border-orange-400  w-full lg:w-80 h-64 p-2 rounded-lg"
              ></textarea>
              <ReCAPTCHA
                sitekey="6LdhMXAqAAAAACkkgwcihlC8AJ31Vpx_fwDtJ89m"
                onChange={handleRecaptchaChange}
              />
              <button
                style={{ borderColor: "rgb(0,37,83)" }}
                disabled={!recaptchaValue}
                className={`hidden lg:block text-white border rounded-lg hover:opacity-90
                 text-lg font-bold bg-customBlue w-full py-2 ${
                   !recaptchaValue ? "opacity-50 cursor-not-allowed" : ""
                 }`}
              >
                <b>{language === 0 ? "Enviar" : "Send"}</b>
              </button>
            </div>

            <button
              type="submit"
              disabled={!recaptchaValue}
              className={`block lg:hidden mt-5 text-white border rounded-lg hover:opacity-90
              text-lg font-bold bg-customBlue w-full py-2 ${
                !recaptchaValue ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              <b>{language === 0 ? "Enviar" : "Send"}</b>
            </button>
          </form>
        </div>
      </div>
      <div>
        <div className="w-full bg-white lg:rounded-news lg:py-20 overflow-hidden">
          <div className="max-w-7xl mx-auto flex justify-center px-3 lg:flex-col flex-col-reverse items-center">
            <div
              style={{ borderColor: "rgb(0,37,83)" }}
              className="hidden md:block relative border-4 overflow-hidden"
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.295064683809!2d-77.0285976!3d-12.0919413!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c866ba40bb4b%3A0xd79c8dc2d65821df!2sPiso%209%2C%20Av.%20Javier%20Prado%20Este%20488%2C%20San%20Isidro%2015046!5e0!3m2!1sen!2spe!4v1707061729941!5m2!1sen!2spe"
                width="600"
                height="600"
                title="maps"
                style={{ border: 0, width: 800, zIndex: 0 }}
                allowFullScreen={false}
                loading="lazy"
                className="mx-auto "
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
              {/* <div
                className="bg-customBlue hidden lg:flex flex-col items-center absolute
                top-5 right-5 rounded-lg p-3 space-y-2 shadow-lg"
              >
                <p className="text-white">
                  {language === 0
                    ? "Encuéntranos en Google"
                    : "Find us on Google"}{" "}
                  <br />
                  Maps.
                </p>
                <button
                  onClick={() => {
                    window.open(
                      "https://maps.app.goo.gl/Mu1vCc6GX31JRiDw9?g_st=iwb",
                      "_blank"
                    );
                  }}
                  className="mx-auto cursor-pointer bg-white px-4 py-2 text-orange-400 font-bold rounded-lg "
                >
                  {language === 0 ? "Haz click aquí" : "Click here"}
                </button>
              </div> */}
            </div>

            <div className="mt-4 mb-4 lg:mb-0">
              <p className="text-customOrange lg:text-5xl text-3xl font-bold text-center ">
                {language === 0
                  ? "Siguenos en Redes"
                  : "Follow us on social media"}
              </p>
              <div className="mt-4 flex justify-between">
                <a
                  href="https://www.instagram.com/montaltoasociados/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon
                    className="lg:w-16 lg:h-16 w-12 h-12"
                    icon={"skill-icons:instagram"}
                  />
                </a>
                <a
                  href="https://www.linkedin.com/in/rossana-montalto-herrera-417482112/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon
                    className="lg:w-16 lg:h-16 w-12 h-12"
                    icon={"skill-icons:linkedin"}
                  />
                </a>
                <a
                  href="https://wa.link/02xvlk"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon
                    className="lg:w-16 lg:h-16 w-12 h-12"
                    icon={"logos:whatsapp-icon"}
                  />
                </a>
                <a
                  href="https://tr.ee/oX4IONBamZ"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon
                    className="lg:w-16 lg:h-16 w-12 h-12"
                    icon={"simple-icons:linktree"}
                  />
                </a>
                <a
                  href="https://goo.gl/maps/Jh2jd2E4NuoKRMTu9"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon
                    className="lg:w-16 lg:h-16 w-12 h-12"
                    icon={"logos:google-maps"}
                  />
                </a>
              </div>
            </div>

            <div
              onClick={() => {
                window.open("https://goo.gl/maps/Jh2jd2E4NuoKRMTu9", "_blank");
              }}
              style={{ borderColor: "rgb(0,37,83)" }}
              className="block md:hidden relative border-2 rounded-lg overflow-hidden "
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.2920990592233!2d-77.0281674!3d-12.0921446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c866a4359165%3A0xd6fd3a83f46c5097!2sC.%20Las%20Orqu%C3%ADdeas%20444%2C%20San%20Isidro%2015046!5e0!3m2!1sen!2spe!4v1672421884382!5m2!1sen!2spe"
                width="600"
                height="450"
                title="maps"
                style={{
                  border: 0,
                  width: 900,
                  zIndex: 0,
                  pointerEvents: "none",
                }}
                allowFullScreen={false}
                loading="lazy"
                className="mx-auto "
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
              {/* <div
                style={{ background: "rgb(0,37,83)" }}
                className=" flex-col items-center flex lg:hidden absolute bottom-2 right-2 rounded-lg p-2 space-y-2 shadow-lg"
              >
                <p className="text-white">
                  {language === 0
                    ? "Encuéntranos en Google"
                    : "Find us on Google"}{" "}
                  <br />
                  Maps.​
                </p>
                <button
                  onClick={() => {
                    window.open(
                      "https://goo.gl/maps/Jh2jd2E4NuoKRMTu9",
                      "_blank"
                    );
                  }}
                  className=" cursor-pointer bg-white px-4 py-2 text-orange-400 font-bold rounded-lg "
                >
                  {language === 0 ? "Haz click aquí" : "Click here"}
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
