import Agricola from "../assets/Sectors/Agricola.png";
import Comercial from "../assets/Sectors/Comercial.png";
import Consultoria from "../assets/Sectors/Consultoria.png";
import Educacion from "../assets/Sectors/Educacion.png";
import Industrial from "../assets/Sectors/Industrial.png";
import Inmobiliario from "../assets/Sectors/Inmobiliario.png";
import Mineria from "../assets/Sectors/Mineria.png";
import ONG from "../assets/Sectors/ONG.png";
import Servicios from "../assets/Sectors/Servicios.png";
import Textil from "../assets/Sectors/Textil.png";

const clients = [
  {
    name: "Textil",
    englishName: "Textil",
    textColor: "orange",
    image: Textil,
    width: 160,
    height: 140,
  },

  {
    name: "Agricola",
    englishName: "Agricultural",
    textColor: "blue",
    image: Agricola,
    width: 160,
    height: 140,
  },
  {
    name: "Consultoría",
    englishName: "Consulting",
    textColor: "orange",
    image: Consultoria,
    width: 160,
    height: 140,
  },
  {
    name: "Minería",
    englishName: "Mining",
    textColor: "blue",
    image: Mineria,
    width: 160,
    height: 140,
  },
  {
    name: "Inmobiliario",
    englishName: "Real State",
    textColor: "orange",
    image: Inmobiliario,
    width: 160,
    height: 140,
  },
  {
    name: "Educación",
    englishName: "Education",
    textColor: "blue",
    image: Educacion,
    width: 160,
    height: 140,
  },
  {
    name: "ONG",
    englishName: "ONG",
    textColor: "orange",
    image: ONG,
    width: 160,
    height: 140,
  },
  {
    name: "Industrial",
    englishName: "Industrial",
    textColor: "blue",
    image: Industrial,
    width: 160,
    height: 140,
  },
  {
    name: "Comercial",
    englishName: "Commercial",
    textColor: "orange",
    image: Comercial,
    width: 160,
    height: 140,
  },
  {
    name: "Servicios",
    englishName: "Services",
    textColor: "blue",
    image: Servicios,
    width: 160,
    height: 140,
  },
];

export default clients;
