const services = [
    {
        name: "Auditoría externa",
        englishName: "External Audit",
    },
    {
        name: "Consultoría En NIIF",
        englishName: "Advice On IFRS",
    },
    {
        name: "Servicios de back office",
        englishName: "Back Office services",
    },
    {
        name: "Auditoría Interna​",
        englishName: "Internal Audit",
    },
    {
        name: "Inventarios y tasaciones",
        englishName: "Inventories",
    },
    {
        name: "Impuestos Y Legal",
        englishName: "Tax And Legal",
    },
    {
        name: "Valorización de empresas e intangibles",
        englishName: "Business Consulting",
    },
    {
        name: "Capacitaciones In-House",
        englishName: "In-house training",
    },
]

export default services;