import React, { useContext, useEffect, useState } from "react";
import { ReorderThree, ChevronDownOutline } from "react-ionicons";
import { UserContext } from "../Pages/Home";
import { ReactComponent as SpainFlag } from "../assets/SpainFlag.svg";
import { ReactComponent as UsaFlag } from "../assets/UsaFlag.svg";
import { AnimatePresence, motion } from "framer-motion";
import "./animation.css";
import Logo from "../assets/Logo/morrison_logo.png";

interface SubLink {
  title: string;
  href: string;
  idx?: number;
  onClick?: (setShowModal: any, showModal: any) => void;
}

interface Link {
  title: (lan: number) => string;
  subLinks: (lan: number) => SubLink[];
  href: string;
}

const links: Link[] = [
  {
    title: (lan) => (lan === 0 ? "Inicio" : "Home"),
    subLinks: () => [],
    href: "#top",
  },
  {
    title: (lan) => (lan === 0 ? "Nosotros" : "Us"),
    subLinks: (lan: any) => [
      { title: lan === 0 ? "Nosotros" : "Us", href: "#us" },
      { title: lan === 0 ? "Staff" : "Staff", href: "#staff" },
    ],
    href: "#us",
  },
  {
    title: (lan) => (lan === 0 ? "Servicios" : "Services"),
    subLinks: (lan) => [
      {
        title: lan === 0 ? "Auditoria externa" : "External audit",
        href: "#services",
        idx: 0,
      },
      {
        title: lan === 0 ? "Consultoría en NIIF" : "NIIF consulting",
        href: "#services",
        idx: 1,
      },
      {
        title: lan === 0 ? "Servicios de back office" : "Back office services",
        href: "#services",
        idx: 2,
      },
      {
        title: lan === 0 ? "Auditoría interna" : "Internal audit",
        href: "#services",
        idx: 3,
      },
      {
        title:
          lan === 0
            ? "Inventarios y tasaciones​"
            : "Inventories and appraisals",
        href: "#services",
        idx: 4,
      },
      {
        title: lan === 0 ? "Impuestos y legal" : "Taxes and legal",
        href: "#services",
        idx: 5,
      },
      {
        title:
          lan === 0
            ? "Valorización de empresas e intangibles"
            : "Valuation of companies and intangibles",
        href: "#services",
        idx: 6,
      },
      {
        title: lan === 0 ? "Capacitaciones in-house" : "In-house training",
        href: "#services",
        idx: 7,
      },
    ],
    href: "#services",
  },
  {
    title: (lan) => (lan === 0 ? "Sectores Atendidos" : "Sectors Served"),
    subLinks: () => [],
    href: "#sectors",
  },
  {
    title: (lan) => (lan === 0 ? "Contacto" : "Contact"),
    subLinks: (lan: any) => [
      {
        title: lan === 0 ? "Información" : "Information",
        href: "#information",
      },
      { title: lan === 0 ? "Contáctanos" : "Contact Us", href: "#contact" },
    ],
    href: "#contact",
  },
];

function Navbar() {
  const [isSticky, setIsSticky] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [expandedServices, setExpandedServices] = useState(false);
  const [linkHover, setLinkHover] = useState("");

  const { setShowModal, setContentIdx, showModal, language, setLanguage } =
    useContext(UserContext);

  useEffect(() => {
    const handleScroll = () => setIsSticky(window.scrollY > 0);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleServicesMenu = () => {
    setExpandedServices(!expandedServices);
  };

  const renderLinks = () =>
    links.map((obj, index) => (
      <div
        key={index}
        className="relative"
        onMouseEnter={() => {
          setExpandedServices(true);
          setLinkHover(obj.title(language));
        }}
        onMouseLeave={() => {
          setExpandedServices(false);
          setLinkHover("");
        }}
      >
        <a className="text-base text-gray-700" href={obj.href}>
          {obj.title(language)}
        </a>
        {expandedServices && linkHover === obj.title(language) && (
          <div className="absolute bg-orange-400 shadow-lg rounded w-48">
            {obj.subLinks(language).map((sub, idx) => (
              <a
                key={idx}
                href={sub.href}
                onClick={(e) => {
                  e.preventDefault();
                  const targetElement = document.querySelector(sub.href);
                  if (targetElement) {
                    targetElement.scrollIntoView({
                      behavior: "smooth",
                    });
                  }
                  if (obj.title(language) === "Servicios") {
                    if (sub.onClick) {
                      sub.onClick(setShowModal, showModal);
                    } else {
                      setShowModal(true);
                    }
                    if (sub.idx !== null && sub.idx !== undefined) {
                      setContentIdx(sub.idx);
                    }
                  }
                }}
                className={`text-sm text-white hover:bg-orange-500 block px-3 py-2 ${
                  idx + 1 !== obj.subLinks(language).length
                    ? "border-b border-white"
                    : ""
                }`}
              >
                {sub.title}
              </a>
            ))}
          </div>
        )}
      </div>
    ));

  return (
    <div className="fixed top-0 left-0 w-full bg-white shadow-sm z-50">
      {/* Banner superior, siempre visible */}
      <div className="bg-customBlue w-full text-xs lg:text-sm flex h-8 justify-between items-center px-10 py-4">
        <div className="flex space-x-7 items-center">
          <a href="#top">
            <img width={120} height={120} src={Logo} alt="Favicon" />
          </a>
          <a href="tel:+5117026125" className="text-white">
            +51 1 7026125
          </a>
          <a
            href="mailto:info@montaltoasociados.com"
            className="text-white truncate md:text-clip"
          >
            info@montaltoasociados.com
          </a>
        </div>
        <p
          onClick={() => setLanguage(language === 0 ? 1 : 0)}
          className="cursor-pointer text-white flex items-center"
        >
          {language === 0 ? "ES" : "EN"}
          {language === 0 ? (
            <SpainFlag className="w-3 h-3 ml-2" />
          ) : (
            <UsaFlag className="w-3 h-3 ml-2" />
          )}
        </p>
      </div>

      {/* Navbar principal */}
      <div
        className={`bg-white ${
          isSticky ? "fixed top-0 left-0 w-full shadow-lg" : "relative"
        } flex md:justify-evenly justify-between h-16 items-center px-10`}
      >
        <a href="#top" className="flex items-center">
          <img
            src="https://i.ibb.co/44NpMQc/image.png"
            className="mr-3 h-10"
            alt="Logo"
          />
        </a>
        <div className="hidden lg:flex space-x-10 items-center">
          {renderLinks()}
        </div>
        <ReorderThree
          aria-label="Toggle menu"
          aria-expanded={showMenu}
          onClick={() => setShowMenu(!showMenu)}
          color="grey"
          cssClasses="cursor-pointer md:hidden"
          width="30px"
          height="30px"
        />
      </div>

      {/* Menú responsive */}
      <AnimatePresence>
        {showMenu && (
          <motion.div
            initial={{ opacity: 0, y: 0 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="fixed inset-0 w-full h-full bg-white z-40 overflow-y-auto pt-24"
          >
            <div className="absolute top-0 left-0 w-full bg-white">
              <div className="flex justify-between items-center h-16 px-10 bg-white shadow-sm">
                <a href="#top" className="flex items-center">
                  <img
                    src="https://i.ibb.co/44NpMQc/image.png"
                    className="mr-3 h-10"
                    alt="Logo"
                  />
                </a>
                <ReorderThree
                  aria-label="Close menu"
                  onClick={() => setShowMenu(false)}
                  color="grey"
                  cssClasses="cursor-pointer"
                  width="30px"
                  height="30px"
                />
              </div>
            </div>

            <div className="px-10">
              {links.map((obj, index) => (
                <div key={index} className="w-full">
                  <a
                    href={obj.href}
                    className="text-gray-700 py-4 w-full border-b flex items-center justify-center cursor-pointer hover:bg-slate-100"
                    onClick={() => {
                      if (
                        obj.title(language) === "Servicios" ||
                        obj.title(language) === "Services"
                      ) {
                        toggleServicesMenu();
                      } else {
                        setShowMenu(false);
                      }
                    }}
                  >
                    {obj.title(language)}
                    {obj.title(language) === "Servicios" ||
                    obj.title(language) === "Services" ? (
                      <ChevronDownOutline
                        color="grey"
                        cssClasses="ml-2"
                        height="20px"
                        width="20px"
                      />
                    ) : null}
                  </a>
                  {expandedServices &&
                    obj.title(language) === "Servicios" &&
                    obj.subLinks(language).map((sub, idx) => (
                      <a
                        key={idx}
                        href={sub.href}
                        className="text-gray-700 bg-gray-200 py-3 px-4 border-b block text-center cursor-pointer hover:bg-slate-100"
                        onClick={(e) => {
                          e.preventDefault();

                          const targetElement = document.querySelector(
                            sub.href
                          );
                          if (targetElement) {
                            targetElement.scrollIntoView({
                              behavior: "smooth",
                            });
                          }

                          if (sub.onClick) {
                            sub.onClick(setShowModal, showModal);
                          } else {
                            setShowModal(true);
                          }

                          if (sub.idx !== null && sub.idx !== undefined) {
                            setContentIdx(sub.idx);
                          }

                          setShowMenu(false);
                        }}
                      >
                        {sub.title}
                      </a>
                    ))}
                </div>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default Navbar;
